const config = {
    // Production 
    // endpoint: "https://backend.bmc.ourcodelab.com",

    // Staging
    endpoint: "https://backend.staging.bmc.ourcodelab.com",
    local_url: "https://staging.bmc.ourcodelab.com",
    endpoint_1: "https://backend.staging.bmc.ourcodelab.com",

    // Development
    // endpoint_1: "http://192.168.1.91:4000",
    // endpoint: "http://127.0.0.1:4000",

    /**
     * Endpoint_1 is for the globe
     */

    // endpoint_1: "http://192.168.1.89:4000",
    // endpoint_1: "http://192.168.1.35:4000",
    version: "1.1.0 (161224)",
    developer: "OurCodeLab LLP",
    website: "https://ourcodelab.com",

    client_name: "Bedok Methodist Church",
    client_app: "BMC plus",

    feature_flag: {
        allow_registration_web: true,
    },

    images: {
        default: {
            logo: `/logo.png`,
            logo_white: `/logoWhite.png`,
        }
    }

}

export default config